// SELECTORS
const links = document.querySelectorAll(".m-navigation__link");
const navDropdown = document.querySelector('.nav__drop');
const navSub = document.querySelector('.nav__sub');

console.log(navDropdown);


for (let i = 0; i < links.length; i++)
    links[i].addEventListener("click", function () {
        document.getElementById("navi-toggle").checked = false;
        document.querySelector(".m-navigation__list").classList.toggle("hidden");
    });

document.getElementById("navi-toggle").addEventListener("change", function () {
    document.querySelector(".m-navigation__list").classList.toggle("hidden");
});

const scrollDist = document.getElementById("home").scrollHeight + document.querySelector('.nav').scrollHeight;
window.onscroll = function () {
    if (document.body.scrollTop > scrollDist || document.documentElement.scrollTop > scrollDist) {
        document.querySelector('.nav').classList.add('fixed-top')
        document.querySelector('.filler').classList.remove('none')
    } else {
        document.querySelector(".nav").classList.remove("fixed-top");
        document.querySelector('.filler').classList.add('none')
    }
};


navDropdown.addEventListener('mouseenter', function () {
    navSub.classList.add('sub--active')
})

navDropdown.addEventListener('mouseleave', function () {
    navSub.classList.remove('sub--active')
})
